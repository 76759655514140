<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :inRowBtnRead="false"
      :expandable="false"
      :showExpand="false"
      :filterSelects="_filterSelects"
      :pageMode="true"
      :labelInLocale="true"
      :customTableActions="customTableActions"
    ></WsCrud>
  </WsMain>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page";
export default {
  metaInfo() {
    return {
      title: this.$t("Page"),
    };
  },

  data: () => ({
    modelName: "tulpa_page",
    label: tulpa_page.label,
    fields: tulpa_page.fields,
    showFields: ["name", "route"],
    customTableActions: [
      {
        icon: "icon-ws-filled-settings",
        getUrl: (item) => {
          return `/tulpa/${item.id}/update/setting`;
        },
        tooltip: "Setting",
      },
    ],
  }),

  computed: {
    _filterSelects() {
      return {
        status: {
          type: "select",
          items: [
            {
              text: this.$case.capitalCase(this.$t("all")),
              value: "all",
              params: {},
            },
            {
              text: this.$t("un_supply"),
              value: 0,
              params: {
                status: 0,
              },
            },
            {
              text: this.$case.capitalCase(this.$t("complete")),
              value: 1,
              params: {
                status: 1,
              },
            },
          ],
        },
      };
    },
  },
};
</script>