import config from '@/__config'
const model = {
  modelName: "tulpa_page",
  label: "頁面",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    tulpa_page_template: {
      type: 'belongsTo',
      label: 'Page Template',
      textKey: "name",
      modelName: "tulpa_page_template",
      labelInLocale: true,
    },
    name: {
      type: "text",
      label: "頁面名稱 (後台顯示)",
      rules: 'required',
      autofocus: true,
      labelInLocale: true,
    },
    content: {
      type: "payload",
      label: "content",
    },
    route: {
      type: "link",
      label: "Route",
      rules: 'required',
      col: 6,
      displayInText: true,
    },
    title: {
      type: "text",
      label: "頁面標題 (呈現於搜尋結果之標題)",
      rules: 'required',
      labelInLocale: true,
      col: 6,
    },
    description: {
      type: "textarea",
      label: "頁面說明 (呈現於搜尋結果之說明文字)",
      rules: 'required',
      labelInLocale: true,
    },
    og_image: {
      type: 'link',
      label: "頁面分享圖片 (分享網址時顯示之圖片)",
      labelInLocale: true,
      col: 6,
    },
    is_active: {
      type: 'switch',
      label: '頁面發布',
      activeText: 'On',
      inactiveText: 'Off',
      defaultValue: false,
      labelInLocale: true,
    },
    tags: {
      type: 'tags',
      label: 'Tags',
      col: 6,
    },
    remark: {
      type: 'textarea',
      label: '頁面備註',
      labelInLocale: true,
    },
    canonical_url: {
      type: 'link',
      label: 'Canonical URL',
      col: 6,
    },
    tulpa_cross_items: {
      type: 'belongsToMany',
      label: '跨網站項目',
      modelName: 'tulpa_cross_item',
      labelInLocale: true,
      textKey: "name",
    },
    updated_at: {
      type: "datetime",
      label: "updated_at",
      readonly: true,
      labelInLocale: true,
    },
    updated_admin: {
      type: "belongsTo",
      label: "updated_admin",
      readonly: true,
      textKey: "name",
      modeldataName: "admin",
      labelInLocale: true,
    },
    created_admin: {
      type: "belongsTo",
      label: "created_admin",
      readonly: true,
      textKey: "name",
      modeldataName: "admin",
      labelInLocale: true,
    },
  },
}

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'cmser_group',
      labelInLocale: true,
    }
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'admin_group',
      labelInLocale: true,
    }
  }
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
}

export default model